import React from "react"
import Layout from "../Components/Layout"

export default function Home() {
  return (
    <Layout>
      <div>Hello world!</div>
    </Layout>
  )
}
